import { NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { AngebotAbhlenenDialogBoxComponent } from 'src/app/component/dialog-boxes/angebot-abhlenen-dialog-box/angebot-abhlenen-dialog-box.component';
import { LoaderComponent } from 'src/app/component/loader/loader.component';
import { TranslationDirective } from 'src/app/component/translata-modal/translation.directive';
import { Status } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { Agree21Service } from 'src/app/services/agree21.service';
import { AuthService } from 'src/app/services/auth.service';
import { FinapiService } from 'src/app/services/finapi.service';
import { productMatrixService } from 'src/app/services/product-matrix.service';
import { environment } from 'src/environments/environment';

interface CreditCheckResult {
  derivedCreditLimitAmount: number;
  derivedCreditCardLimitAmount: number;
  decisionCredit: string;
  decisionAccount: string;
}

enum ProcessStatus {
  InProgress = 'inprogress',
  Success = 'success',
  Failed = 'failed',
}

@Component({
  selector: 'app-kreditcheckabschluss',
  templateUrl: './kreditcheckabschluss.component.html',
  styleUrls: ['./kreditcheckabschluss.component.scss'],
  imports: [NgIf, TranslationDirective, TranslateModule, ButtonComponent, MatIcon, LoaderComponent],
})
export class KreditcheckabschlussComponent implements OnInit {
  @Output() next = new EventEmitter<void>();
  @Output() documents = new EventEmitter<CreditCheckResult>();

  public state = {
    creditLimitAmount: '',
    creditCardLimitAmount: '',
    creditLimitAmountNumber: 0,
    creditCheckStatus: ProcessStatus.InProgress,
    natPersonKontokorrentStatus: ProcessStatus.InProgress,
    creditCardOrdered: false,
    kontoKorrentOrdered: false,
    skipFinapiProcess: false,
    creditCardSuccess: false,
    kontoKorrentSuccess: false,
    angebotAccepted: false,
    getOffer: false,
    finapiStop: false,
    waiting: false,
    deciedOffering: false,
    berufsGruppe: null as any,
  };

  constructor(
    private readonly finapiService: FinapiService,
    public readonly auth: AuthService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly agree21Service: Agree21Service,
    private readonly adminService: AdminService,
    public readonly productMatrixService: productMatrixService
  ) {
    this.initializeUserPreferences();
  }

  ngOnInit(): void {
    this.updateCreditCheckStatus();
    this.productMatrixService
      .getBerufsGruppe(this.auth.user.natPersonen[this.auth.index].BranchenInfo.Art)
      .subscribe((berufsGruppe) => {
        this.state.berufsGruppe = berufsGruppe;
      });
  }

  getPrimaryButtonText(): string {
    const hasSuccess =
      (this.state.kontoKorrentOrdered && this.state.kontoKorrentSuccess) ||
      (this.state.creditCardOrdered && this.state.creditCardSuccess);
    return hasSuccess ? 'Angebot annehmen' : 'Kontoverbindung starten';
  }

  hasSuccessfulOffer(): boolean {
    return (
      (this.state.kontoKorrentOrdered && this.state.kontoKorrentSuccess) ||
      (this.state.creditCardOrdered && this.state.creditCardSuccess)
    );
  }

  hasPartialSuccess(): boolean {
    return (
      this.state.creditCardOrdered &&
      this.state.kontoKorrentOrdered &&
      !this.state.kontoKorrentSuccess &&
      this.state.creditCardSuccess
    );
  }

  isCompleteFailure(): boolean {
    return (
      (this.state.creditCardOrdered &&
        this.state.kontoKorrentOrdered &&
        !this.state.kontoKorrentSuccess &&
        !this.state.creditCardSuccess) ||
      (this.state.kontoKorrentOrdered && !this.state.creditCardOrdered && !this.state.kontoKorrentSuccess) ||
      (this.state.creditCardOrdered && !this.state.kontoKorrentOrdered && !this.state.creditCardSuccess)
    );
  }

  private async updateCreditCheckStatus(): Promise<void> {
    this.state.creditCheckStatus = this.state.skipFinapiProcess ? ProcessStatus.Success : ProcessStatus.InProgress;

    try {
      await (this.state.creditCheckStatus === ProcessStatus.InProgress
        ? this.handleFullCreditCheck()
        : this.handleSimpleCreditCheck());
    } catch (error) {
      this.handleCreditCheckError();
    }
  }

  private async handleFullCreditCheck(): Promise<void> {
    const session = window.sessionStorage;
    if (!session.getItem('decisionAccount')) {
      await this.finapiService.buildCreditCheckobj();
      const result = await firstValueFrom(
        this.finapiService.creditCheck(
          this.finapiService.PROCESS_TOKEN,
          this.finapiService.ACCESS_TOKEN,
          this.state.creditCardOrdered,
          this.finapiService.creditCheckType
        )
      );
      this.storeCreditCheckResult(result);
      this.processFullCheckResult(result);
    } else {
      this.processFullCheckResult({
        decisionCredit: session.getItem('decisionCredit') as string,
        decisionAccount: session.getItem('decisionAccount') as string,
        derivedCreditLimitAmount: Number(session.getItem('derivedCreditLimitAmount')),
        derivedCreditCardLimitAmount: Number(session.getItem('derivedCreditCardLimitAmount')),
      });
    }
  }

  private async handleSimpleCreditCheck(): Promise<void> {
    let decisionAccount: string;
    const session = window.sessionStorage;

    if (!session.getItem('decisionAccount')) {
      await this.finapiService.buildCreditCheckobjSimple();
      const result = await firstValueFrom(
        this.finapiService.creditCheckWithoutWebForm(this.state.creditCardOrdered, this.finapiService.creditCheckType)
      );
      this.documents.emit(result);
      decisionAccount = result.decisionAccount;
      session.setItem('decisionAccount', decisionAccount);
    } else {
      decisionAccount = session.getItem('decisionAccount') as string;
    }

    this.state.getOffer = true;
    await this.processSimpleCheckResult(decisionAccount);
  }

  async nextstep(): Promise<void> {
    this.state.angebotAccepted = true;
    this.state.waiting = true;

    const isGbR = this.auth.user?.JurPerson?.JurDaten?.GbrArt === 'GbR ohne Haftungsbeschraenkung';
    const processMap = {
      jurPerson: () => this.processJurPerson(),
      gbrPerson: () => this.processGbRPerson(),
      natPerson: () => this.processNatPerson(),
    };

    await (this.auth.isJurPerson() && !isGbR
      ? processMap.jurPerson()
      : isGbR
        ? processMap.gbrPerson()
        : processMap.natPerson());
  }

  private initializeUserPreferences(): void {
    const userAnswer = this.auth.user.questions[0]?.answer;
    this.state.creditCardOrdered = userAnswer?.kreditKarte ?? false;
    this.state.kontoKorrentOrdered = userAnswer?.kontokorrentkredit ?? false;
    this.state.skipFinapiProcess =
      !userAnswer?.kontoWechselService && !userAnswer?.kontokorrentkredit && !userAnswer?.kreditKarte;
  }

  private storeCreditCheckResult(result: CreditCheckResult): void {
    this.documents.emit(result);
    const session = window.sessionStorage;
    session.setItem('derivedCreditLimitAmount', result.derivedCreditLimitAmount.toString());
    session.setItem('derivedCreditCardLimitAmount', result.derivedCreditCardLimitAmount.toString());
    session.setItem('decisionCredit', result.decisionCredit);
    session.setItem('decisionAccount', result.decisionAccount);
  }

  private processFullCheckResult(result: CreditCheckResult): void {
    this.state.creditLimitAmountNumber = result.derivedCreditLimitAmount;

    if (result.decisionCredit === 'GREEN' && result.derivedCreditLimitAmount >= 0) {
      this.handleGreenDecision(result);
    } else if (result.decisionCredit === 'RED') {
      this.handleRedDecision(result.decisionAccount);
    }
  }

  private handleGreenDecision(result: CreditCheckResult): void {
    this.state.creditCheckStatus = ProcessStatus.Success;
    this.state.getOffer = true;

    this.state.kontoKorrentSuccess = result.derivedCreditLimitAmount !== 0;
    if (this.state.kontoKorrentSuccess) {
      this.state.creditLimitAmount = this.formatCurrency(result.derivedCreditLimitAmount);
    }

    this.state.creditCardSuccess = this.state.creditCardOrdered && result.derivedCreditCardLimitAmount !== 0;
    if (this.state.creditCardSuccess) {
      this.state.creditCardLimitAmount = this.formatCurrency(result.derivedCreditCardLimitAmount);
    }

    this.finalizeFinapiProcess();
    this.updateSubStatus('offering');
  }

  private async processSimpleCheckResult(decisionAccount: string): Promise<void> {
    decisionAccount === 'GREEN' ? this.setOfferSuccess() : this.setOfferFailure();
  }

  private setOfferSuccess(): void {
    this.state.deciedOffering = true;
    this.state.getOffer = true;
    this.state.kontoKorrentSuccess = true;
    this.state.creditCardSuccess = true;
    this.updateSubStatus('offering');
  }

  private setOfferFailure(): void {
    this.state.finapiStop = true;
    this.state.getOffer = false;
    this.state.kontoKorrentSuccess = false;
    this.state.creditCardSuccess = false;
    this.state.creditCheckStatus = ProcessStatus.Failed;
    this.updateSubStatus('Account_rejected');
  }

  private async processJurPerson(): Promise<void> {
    this.state.natPersonKontokorrentStatus = ProcessStatus.InProgress;

    if (!this.auth.user?.JurPerson.JurDaten.BBKundennummer) {
      await this.handleNewJurPerson();
    } else {
      this.finalizeProcess();
    }
  }

  private async processGbRPerson(): Promise<void> {
    this.state.natPersonKontokorrentStatus = ProcessStatus.InProgress;

    if (!this.auth.user?.JurPerson.JurDaten.BBKundennummer) {
      await this.handleNewGbRPerson();
    } else {
      this.finalizeProcess();
    }
  }

  private async processNatPerson(): Promise<void> {
    this.state.natPersonKontokorrentStatus = ProcessStatus.InProgress;

    if (!this.auth.user?.kundenNummer) {
      await this.handleNewNatPerson();
    } else {
      this.finalizeProcess();
    }
  }

  private async handleNewJurPerson(): Promise<void> {
    if (this.state.skipFinapiProcess) this.state.creditLimitAmountNumber = 0;

    this.agree21Service.postJurPerson(this.state.creditLimitAmountNumber).subscribe({
      next: (result) => {
        if (result.jurPersonnummer) {
          this.updateUserJurData(result);
          this.finalizeProcess();
        }
      },
      error: (error) => this.handleError(error),
    });
  }

  private async handleNewGbRPerson(): Promise<void> {
    if (this.state.skipFinapiProcess) this.state.creditLimitAmountNumber = 0;

    this.agree21Service.postGbRPerson(this.state.creditLimitAmountNumber).subscribe({
      next: (result) => {
        if (result.gbrPersonnummer) {
          this.updateUserGbRData(result);
          this.finalizeProcess();
        }
      },
      error: (error) => this.handleError(error),
    });
  }

  private async handleNewNatPerson(): Promise<void> {
    if (this.state.skipFinapiProcess) this.state.creditLimitAmountNumber = 0;

    this.agree21Service.postNatPersonKontokorrent(this.state.creditLimitAmountNumber).subscribe({
      next: (result) => {
        if (result.personennummer) {
          this.updateUserNatData(result);
          this.finalizeProcess();
        } else {
          this.router.navigateByUrl('/info/error-page');
        }
      },
    });
  }

  private updateUserJurData(result: any): void {
    this.updateUserData(result.jurPersonnummer, result);
  }

  private updateUserGbRData(result: any): void {
    this.updateUserData(result.gbrPersonnummer, result);
  }

  private updateUserNatData(result: any): void {
    this.agree21Service.PERSONEN_NUMMER = result.personennummer;
    this.agree21Service.IBAN = result.iban;
    this.auth.user.kundenNummer = result.personennummer;
    this.auth.user.iban = result.iban;
    this.auth.user.tagesGeldkontoIban = result.tagesGeldkontoIban;
  }

  private updateUserData(personNumber: number, result: any): void {
    this.agree21Service.PERSONEN_NUMMER = personNumber;
    this.agree21Service.IBAN = result.iban;
    this.auth.user.JurPerson.JurDaten.BBKundennummer = personNumber;
    this.auth.user.iban = result.iban;
    this.auth.user.tagesGeldkontoIban = result.tagesGeldkontoIban;
  }

  private finalizeProcess(): void {
    this.state.natPersonKontokorrentStatus = ProcessStatus.Success;
    this.next.emit();
  }

  handleError(error: any): void {
    if (error.status === 429) {
      this.router.navigate(['/info/WartezeitHinweis']);
    } else {
      alert('An error occurred. Please try again later.');
    }
  }

  private async finalizeFinapiProcess(): Promise<void> {
    const accessToken = this.finapiService.ACCESS_TOKEN ?? window.sessionStorage.getItem('accessToken');
    const processToken = this.finapiService.PROCESS_TOKEN ?? this.auth.user.finapiProcessToken;
    await this.finapiService.finalizeProcess(processToken, accessToken);
  }

  private handleCreditCheckError(): void {
    this.router.navigateByUrl('/info/error-page');
  }

  private formatCurrency(amount: number): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumSignificantDigits: 7,
    }).format(amount);
  }

  private updateSubStatus(status: string): void {
    this.adminService.updateSubStatus(this.auth.user._id, status).subscribe(() => {});
  }

  private handleRedDecision(decisionAccount: string): void {
    decisionAccount === 'GREEN' ? this.setOfferSuccess() : this.setOfferFailure();
  }

  public openPopUp(): void {
    this.dialog
      .open(AngebotAbhlenenDialogBoxComponent)
      .afterClosed()
      .subscribe(async (answer) => {
        if (answer) {
          await this.rejectOffer();
        }
      });
  }

  private async rejectOffer(): Promise<void> {
    this.updateSubStatus('offeringRejected');
    this.auth.changeStatus(this.auth.user._id, Status.inactive).subscribe(() => {
      this.openOne();
    });
  }

  openOne() {
    window.location.href = environment.oneDashboardURL;
  }
}
