<div class="container">
  <!-- Offering Decided -->
  <ng-container *ngIf="state.deciedOffering && !state.waiting">
    <div class="card-container">
      <p appTranslationModal [translate]="'FINAPI.KREDITCHECK_KONTOVERBINDUNG'"></p>
    </div>
    <div class="btn-container">
      <app-button data-cy="primary-action" color="primary" (click)="nextstep()">
        {{ getPrimaryButtonText() }}
        <mat-icon>arrow_forward_ios</mat-icon>
      </app-button>
    </div>
  </ng-container>

  <!-- Credit Check Results -->
  <ng-container *ngIf="!state.deciedOffering">
    <!-- Success -->
    <ng-container *ngIf="state.creditCheckStatus === 'success' && !state.angebotAccepted && state.getOffer">
      <div class="card-container">
        <p *ngIf="hasSuccessfulOffer()" appTranslationModal [translate]="'FINAPI.ANGEBOT_TEXT'"></p>

        <p *ngIf="state.creditCardOrdered && state.creditCardSuccess" style="display: flex" class="flex-item">
          <mat-icon class="check-icon">check</mat-icon>
          Kreditkartenlimit: {{ state.creditCardLimitAmount }}
        </p>

        <ng-container *ngIf="state.kontoKorrentOrdered && state.kontoKorrentSuccess">
          <p class="flex-item" style="display: flex">
            <mat-icon class="check-icon">check</mat-icon>
            Kreditlimit: {{ state.creditLimitAmount }}
          </p>
          <p class="flex-item" style="display: flex">
            <mat-icon class="check-icon">check</mat-icon>
            Sollzinssatz: {{ state.berufsGruppe?.kontokorrentkreditZinssatz }} p.a.
          </p>
        </ng-container>

        <p *ngIf="hasPartialSuccess()" class="flex-item" style="display: flex">
          <mat-icon class="check-icon">close</mat-icon>
          Kreditlimit: <span appTranslationModal [translate]="'FINAPI.KREDITCHECK_KEIN_KREDITLIMIT'"></span>
        </p>

        <p *ngIf="isCompleteFailure()" appTranslationModal [translate]="'FINAPI.KREDITCHECK_FAILED'"></p>
        <p
          *ngIf="!state.creditCardOrdered && !state.kontoKorrentOrdered"
          appTranslationModal
          [translate]="'FINAPI.KREDITCHECK_KONTOVERBINDUNG'"
        ></p>
      </div>

      <div class="btn-container">
        <app-button *ngIf="hasSuccessfulOffer()" color="accent" (click)="openPopUp()"> Angebot ablehnen </app-button>
        <app-button color="primary" (click)="nextstep()" data-cy="button_kreditangebot_annehmen_oder_konto_verbinden">
          {{ getPrimaryButtonText() }}
        </app-button>
      </div>
    </ng-container>

    <!-- In Progress -->
    <ng-container *ngIf="state.creditCheckStatus === 'inprogress' && state.getOffer">
      <app-loader
        [text]="'FINAPI.KREDITCHECK_LOADER_TEXT'"
        [subText]="'FINAPI.KREDITCHECK_LOADER_SUBTEXT'"
      ></app-loader>
    </ng-container>

    <!-- Failed -->
    <ng-container *ngIf="state.creditCheckStatus === 'failed' && state.getOffer">
      <div class="card-container">
        <p appTranslationModal [translate]="'FINAPI.KEINANGEBOT_TEXT'"></p>
      </div>
    </ng-container>

    <!-- No Offer -->
    <ng-container *ngIf="!state.getOffer">
      <app-loader
        *ngIf="!state.finapiStop"
        [text]="'FINAPI.KREDITCHECK_LOADER_TEXT'"
        [subText]="'FINAPI.KREDITCHECK_LOADER_SUBTEXT'"
      ></app-loader>

      <div *ngIf="state.creditCheckStatus === 'failed'" class="card-container">
        <p appTranslationModal [translate]="'FINAPI.KEINANGEBOT'"></p>
        <div class="btn-box">
          <app-button color="accent" (click)="openOne()">OK</app-button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- Waiting -->
  <ng-container *ngIf="state.waiting">
    <app-loader [text]="'FINAPI.DOKUMENTE_LOADER_TEXT'" [subText]="'FINAPI.DOKUMENTE_LOADER_SUBTEXT'"></app-loader>
  </ng-container>
</div>
