import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '../../component/button/button.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationDirective } from '../../component/translata-modal/translation.directive';

@Component({
  selector: 'WartezeitHinweis',
  template: `
    <div class="error-container">
      <h1 appTranslationModal [translate]="'ERRORPAGE.LIMIT_HEADER'"></h1>
      <p style="margin-bottom: 1rem;" appTranslationModal [translate]="'ERRORPAGE.LIMIT_P'"></p>
      <app-button (click)="goBack()">Zurück</app-button>
    </div>
  `,
  styles: [
    `
      .error-container {
        text-align: center;
        padding: 70px;
      }
    `,
  ],
  imports: [ButtonComponent, TranslationDirective, TranslateModule],
})
export class RateLimitErrorComponent {
  constructor(
    private router: Router,
    private tranlate: TranslateService
  ) {}

  goBack(): void {
    this.router.navigate(['/']);
  }
}
