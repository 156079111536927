import { NgIf } from '@angular/common';
import { Component, inject, isDevMode, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { DecodedToken } from 'src/app/models/accessToken.model';
import { environment } from 'src/environments/environment';
import { Status } from './models/user';
import { AuthService } from './services/auth.service';
import { productMatrixService } from './services/product-matrix.service';

import { WaveBackgroundComponent } from './component/wave-background/wave-background.component';
import { FooterComponent } from './pages/footer-navigation/footer/footer.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [NgIf, RouterOutlet, FooterComponent, WaveBackgroundComponent],
})
export class AppComponent implements OnInit {
  readonly routerActive = inject(ActivatedRoute);
  readonly translate = inject(TranslateService);
  readonly auth = inject(AuthService);
  readonly keycloakService = inject(KeycloakService);
  readonly productMatrix = inject(productMatrixService);

  enterLandingPage: boolean = false;

  ngOnInit(): void {
    // institutionId is coming from One redirect to our Frontend! A User can have multiple institions and the ID is the secelected one
    const urlParams = new URLSearchParams(window.location.search);
    const institutionId = urlParams.get('institutionId') ?? '';
    this.initializeApp(institutionId);
  }

  private async initializeApp(institutionId: string): Promise<void> {
    try {
      const token = await this.keycloakService.getToken();
      this.auth.setKeycloakToken(token);
      const parsedToken = this.decodeToken(token);

      const { institutions, sub: UUID, email } = parsedToken;

      // nur zum Entwickeln, damit man nicht bei jedem Refresh die Parameter neu eingeben muss
      if (isDevMode() && !institutionId) {
        institutionId = Object.keys(institutions)[0];
      }

      this.handleLogin(UUID, email, institutions, institutionId);
    } catch (error) {
      this.redirectToDashboard();
    }
  }

  private decodeToken(token: string): DecodedToken {
    return jwtDecode<DecodedToken>(token);
  }

  private handleLogin(UUID: string, email: string, institutions: any, institutionID): void {
    this.auth.login(UUID, email, institutions, institutionID).subscribe({
      next: (user) => {
        this.processUser(user);
        this.productMatrix
          .getBerufsGruppe(this.auth.user.natPersonen[this.auth.index].BranchenInfo.Art)
          .subscribe((berufsGruppe) => {
            this.auth.setBerufsGruppe(berufsGruppe);
          });
      },
      error: () => this.redirectToDashboard(),
    });
  }

  private processUser(user: any): void {
    if (user) {
      this.setupTranslation();
      this.enterLandingPage = this.isUserAllowed(user.status);
    } else {
      this.enterLandingPage = false;
    }
  }

  private setupTranslation(): void {
    this.translate.addLangs(['odfin']);
    this.translate.setDefaultLang('odfin');
  }

  private isUserAllowed(status: Status): boolean {
    if (status === Status.active || status === Status.finish) {
      return true;
    }
    this.redirectToDashboard();
    return false;
  }

  private redirectToDashboard(): void {
    window.location.href = environment.oneDashboardURL;
  }
}
